<template>
  <div class="box1">
    <h1>AB Diamond Art Painting Store</h1>
    <h2>Website Introduction</h2>
    <h5  style="display: block; text-align: left; margin-left: 20px;">
      Hello everyone! Welcome. I sell diamond paintings here. The following is a brief introduction of the product. If you are interested in the products I sell, or if the products I sell are helpful to you, you can click the jump text below and you will enter my store to view more details.
    </h5>
    <div class="story1">
      <a href="https://s.click.aliexpress.com/e/_ooNeA5t">Jump to the AliExpress store to view product details</a>
    </div>
    <h2>Product Description</h2>
    <h5>
      【Type】5DDIY AB full Drill Diamond Painting<br>
      【Material】HD printed canvas+diamonds<br>
      【Size】The size in our store stand for pasting area image size, canvas size is much bigger after blank space added.<br>
      【Diamonds】With extra 10% extra diamond for painting and pasting area with full drills.<br>
      【Application】Home / hotel / living room / kitchen / office decoration / gift / entertainment /<br>
      【Packing】Roll up without folds.<br>
      【Photo Custom】Customize from 1 piece.<br>
      【Delivery】Fast delivery available, the entire shipping process can be tracked.<br>
      【Frame】Unframed, need buy by yourself.<br>
      【Packing list】canvas, pen, shovel, glue.<br>
      【Notice】Printed canvas without watermark.<br>
    </h5>

    <!-- Add images stacked vertically -->
    <div class="image-container">
      <a href="https://s.click.aliexpress.com/e/_ooNeA5t" target="_blank">
      <img src="../images/1.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/2.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/3.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/4.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/5.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/6.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/7.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/8.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/9.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/10.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/11.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/12.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
      <img src="../images/13.webp" alt="Diamond Painting" style="width: 100%; max-width: 500px; margin-top: 20px;">
    </a>
    </div>

    <div class="story2">
      <a href="https://s.click.aliexpress.com/e/_ooNeA5t">Jump to the AliExpress store to view product details</a>
    </div>
  </div>
</template>
<style>
.box1 {
  background-image: url(../images/ic_bg.png);
  background-size: cover; /* Ensure the background image covers the entire container */
  background-attachment: fixed;
  width: 100%; /* Full width */
  height: 100vh; /* Full height of the viewport */
  padding-left: 20px; /* Optional: Adds padding to the left of the content */
}

h1 {
  text-align: center; /* Center the h1 */
  margin-top: 20px; /* Optional: Adjust the top margin for spacing */
}

h2, h5, .story {
  text-align: left; /* Left-align the rest of the content */
}

h2 {
  margin-top: 20px; /* Optional: Adjust the margin for spacing */
}

h5 {
  margin-top: 20px; /* Optional: Adjust spacing between elements */
  line-height: 1.6; /* Improve readability */
}

.story a {
  color: #007bff; /* Make the link stand out */
  text-decoration: none; /* Remove underline from the link */
}

.story a:hover {
  text-decoration: underline; /* Underline on hover */
}

.image-container {
  display: block; /* Ensure images are block-level */
  width: 100%; /* Ensure images take up full width */
  text-align: center; /* Center the images */
}

img {
  display: block; /* Ensure each image is displayed as a block */
  width: 100%; /* Make the image responsive */
  max-width: 500px; /* Optional: Set a max-width for large screens */
  margin-top: 20px; /* Add space between images */
}
</style>
